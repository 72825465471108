∟<template>
  <!-- <v-card elevation="8" outlined color="grey lighten-4" class="w-11/12 mx-auto"> -->
  <v-row class=" " ref="top">
    <!--  Mobile menu-->
    <div @click.stop="drawer = drawer" style="position: relative; background-color: rgba(255,255,255,0);"
      class="visible md:hidden m-3">
      <!--            <v-app-bar height="10px;" style="background-color: #ca8a04"/>-->
      <v-container>
        <v-row align="center" justify="center">
          <div class="flex flex-row justify-between p-1">
            <!--                  <div>-->
            <!--                    <v-img-->
            <!--                        src="@/assets/logofndy.svg"-->
            <!--                        width="90"-->
            <!--                    />-->
            <!--                  </div>-->
            <!-- <v-icon x-large color="blue darken-2">mdi-menu</v-icon> -->
          </div>
          <div>
            <img class="  w-1/2 mx-auto" src="@/assets/logo.png" alt="logo" />
                    <!-- <hr class="border-2 border-gray-300"> -->
                    <div class="mb-2 text-center">
                    <span class="text-lg text-center text-blue-400">Exclusive Representative for Bulgaria</span>
                    <br>
                    <span class="text-gray-700">Importation and distribution of medical devices</span>
                  </div>
                    <hr class="border-1 border-gray-400">
                  <img class="m-1 w-1/3 mx-auto" src="@/assets/logo-wallaby-phenox.png" alt="phenox" />
                    <hr class="border-1 border-gray-500">
                  <img class="m-1 w-1/3 mx-auto" src="@/assets/logo-kaneka.png" alt="kaneka" />
                    <hr class="border-1 border-gray-600">
                  <img class="m-1  w-1/4 mx-auto" src="@/assets/logo-meril-w.png" alt="meril" />
                    <hr class="border-1 border-gray-700">
                  </div>
        </v-row>
      </v-container>

      <v-navigation-drawer app v-model="drawer" temporary right>
        <v-list-item class="my-4" v-for="link in links" :key="link.text">
          <v-btn elevation="6" block outlined large @click.stop="drawer = false;">
            <div class="flex justify-start">
              <v-icon color="grey lighten-1">{{ link.icon }}</v-icon>
              <router-link :to=link.route><span class="ml-2 text-gray-900">{{ link.text }}</span></router-link>
            </div>
          </v-btn>
        </v-list-item>
        <img class="w-1/2 mx-auto" src="@/assets/logo.png" alt="logo" />
        
        <v-divider>
        </v-divider>
      </v-navigation-drawer>
    </div>


    <div style="background-color: #EDE7E7;"
      class="hidden md:visible  mx-3 pt-2 md:flex w-full flex-nowrap justify-center">
      <div class="">
        <img class="w-36 mt-6 mb-2 mx-2 " src="@/assets/logo.png" alt="Think Tank" />        
      </div>

      <div class="my-auto pt-2">
        <v-btn @click="$refs.AboutUs.scrollIntoView({ behavior: 'smooth' });" x-large text tile>
          ABOUT US
        </v-btn>

        <v-btn @click="$refs.OurProducts.scrollIntoView({ behavior: 'smooth' });" x-large text tile>
          OUR PRODUCTS
        </v-btn>

        <!-- <v-btn @click="$refs.Mission.scrollIntoView({ behavior: 'smooth' });" x-large text tile>
          MISSION & VISION
        </v-btn> -->
        <v-btn x-large text tile @click="$refs.ContactUs.scrollIntoView({ behavior: 'smooth' });">
          CONTACT
        </v-btn>
      </div>
    </div>
    <v-window v-model="step" class=" mx-auto max-w-7xl">
      <v-window-item :value="1">
        <v-col cols="12">
          <div class="text-xl lg:text-2xl font-weight-bold text-center">
            <div class="">


              <!--Page1-->
              <div class="hidden md:visible md:-mt-2 md:flex">
                <img class="page1-img" src="@/assets/Page.jpg" alt="medical image">

                <div class="page1-over-element">
                  <div>
                      <!-- <hr class="border-2 border-gray-300"> -->
                    <img class="mt-2 mb-6 w-2/3 mx-auto" src="@/assets/logo.png" alt="logo" />
                    <span class="text-lg text-center text-blue-400">Exclusive Representative for Bulgaria</span>
                    <div class="mb-8">
                      <span class="text-gray-700">Importation and distribution of medical devices</span>
                    </div>
                      <hr class="border-2 border-gray-400">
                    <img class="m-2 w-1/3 mx-auto" src="@/assets/logo-wallaby-phenox.png" alt="phenox" />
                      <hr class="border-2 border-gray-500">
                    <img class="m-2 w-1/3 mx-auto" src="@/assets/logo-kaneka.png" alt="kaneka" />
                      <hr class="border-2 border-gray-600">
                    <img class="mt-8 w-1/4 mx-auto" src="@/assets/logo-meril-w.png" alt="meril" />
                      <!-- <hr class="border-2 border-gray-700"> -->
                    </div>                    
                </div>
              </div>
            
              <!--Page2-->
              <div ref="AboutUs" class="flex flex-col md:flex-row py-1">
                <!-- <div class="hidden md:flex md:w-1/3">
                  <img class="h-full" src="@/assets/Page2.png" alt="Think Tank" />
                </div> -->
                <div style="background-color: rgb(47, 57, 90);" class="md:w-2/3 flex flex-col font-normal ">
                  <div class=" mx-8 my-4 text-left border-b-2">
                    <span class="text-white">Who We Are</span>
                  </div>

                  <h3 class="text-position-left text-white">Since its inception, the company has been mainly engaged in
                    the import and distribution of medical devices. Think Tank
                    is an exclusive representative of the medical device
                    manufacturers Phenox, Kaneka and Meril. Our company has been working
                    with the National Health Insurance Fund since its
                    establishment It also works in the hospital market as a partner.</h3>

                  <div class="page2-icons-container ">
                    <div class="text-gray-50 md:text-4xl icon-container">
                      <i class="fa fa-handshake-o" aria-hidden="true"></i>
                      <span class="text-sm md:text-2xl font-normal">Health Care</span>
                    </div>

                    <div class="text-gray-50 md:text-4xl icon-container">
                      <i class="fa fa-gratipay" aria-hidden="true"></i>
                      <span class="text-sm md:text-2xl font-normal">Corporate Responsibility</span>
                    </div>

                    <div class="text-gray-50 md:text-4xl icon-container">
                      <i class="fa fa-leanpub" aria-hidden="true"></i>
                      <span class="text-sm md:text-2xl font-normal">Learning and Development</span>
                    </div>
                  </div>
                </div>
              </div>

              <!--Products START-->
              <div ref="OurProducts" style="background-color: rgb(47, 57, 90);"
                class="text-white font-normal bg-gray-50">
                Our Products
              </div>
              <div class="grid grid-cols-4 bg-gray-50">
                <!--First ROW-->
                <div class="border-l-0 border-2"
                  @click="loadPdf('p64'); $refs.top.scrollIntoView({ behavior: 'smooth' });">
                  <img src="@/assets/p64_tile.png" alt="p64">
                  <!-- <span class="md:text-4xl font-bold">p64</span> -->
                  <!-- <br>
                  <span class="text-sm md:text-2xl font-normal">p64</span> -->
                </div>

                <div class="border-l-0 border-2"
                  @click="loadPdf('pconus'); $refs.top.scrollIntoView({ behavior: 'smooth' });">
                  <img class="" src="@/assets/pCONUS_tile.png" alt="pCONUS">
                  <!-- <span class="md:text-4xl font-bold">Catchview</span>
                  <br>
                  <span class="text-sm md:text-2xl font-normal">Revascularization Device</span> -->
                </div>

                <div class="border-l-0 border-2"
                  @click="loadPdf('avenir'); $refs.top.scrollIntoView({ behavior: 'smooth' });">
                  <img class="avenir" src="@/assets/Avenir_tile.png" alt="Avenir">
                  <!-- <span class="md:text-4xl font-bold">Copernic RC</span>
                  <br>
                  <span class="text-sm md:text-2xl font-normal">Venous Remodelling Balloon</span> -->
                </div>

                <div class="border-r-0 border-l-0 border-2"
                  @click="loadPdf('pegasus'); $refs.top.scrollIntoView({ behavior: 'smooth' });">
                  <img class="" src="@/assets/pEGASUS_tile.png" alt="pEGASUS">
                  <!-- <span class="md:text-4xl font-bold">Eclipse 2L</span>
                  <br>
                  <span class="text-sm md:text-2xl font-normal">Double Lumen Balloon Catheter</span> -->
                </div>
                <!--Second ROW-->
                <div class="border-l-0 border-t-0 border-2"
                  @click="loadPdf('preset'); $refs.top.scrollIntoView({ behavior: 'smooth' });">
                  <img class="" src="@/assets/pRESET_tile.png" alt="pRESET">
                  <!-- <span class="md:text-4xl font-bold">Fargo</span>
                  <br>
                  <span class="text-sm md:text-2xl font-normal">Guiding Catheter</span> -->
                </div>

                <div class="border-l-0 border-t-0 border-2"
                  @click="loadPdf('portal'); $refs.top.scrollIntoView({ behavior: 'smooth' });">
                  <img class="" src="@/assets/pORTAL_tile.png" alt="pORTAL">
                  <!-- <span class="md:text-4xl font-bold">Gama+</span>
                  <br>
                  <span class="text-sm md:text-2xl font-normal">Reinforced Microcatheter</span> -->
                </div>

                <div class="border-l-0 border-t-0 border-2"
                  @click="loadPdf('pita'); $refs.top.scrollIntoView({ behavior: 'smooth' });">
                  <img class="" src="@/assets/pITA_tile.png" alt="pITA">
                  <!-- <span class="md:text-4xl font-bold">Hybrid</span>
                  <br>
                  <span class="text-sm md:text-2xl font-normal">Guidewire</span> -->
                </div>

                <div class="border-r-0 border-l-0 border-t-0 border-2"
                  @click="loadPdf('leo'); $refs.top.scrollIntoView({ behavior: 'smooth' });">
                  <!-- <img class="" src="@/assets/Leo-seul-II-584x584.webp" alt="LEO+"> -->
                  <!-- <span class="md:text-4xl font-bold">LEO+</span>
                  <br>
                  <span class="text-sm md:text-2xl font-normal">Self-Expandable Braided Stent</span> -->
                </div>
 
                

                <!--                  <div class="border-r-0 border-l-0 border-t-0 border-2">-->
                <!--                    <img class="" src="" alt="Squid">-->
                <!--                    <span class="md:text-4xl font-bold">Squid</span>-->
                <!--                    <br>-->
                <!--                    <span class="text-sm md:text-2xl font-normal">Liquid Embolic</span>-->
                <!--                  </div>-->
              </div>
              <!--Products END-->




              <!--Page4-->
              <!-- <div ref="Mission" class="flex pa-ge4-container font-normal">
                <div class="page4-info-container">
                  <h1 class="text-white text-position-left page4-info-item">Mission &amp; Vision</h1> -->
                      <!-- <h3 class="text-white text-position-left page4-info-item">Mission:...................</h3> -->
                      <!-- <h3 class="text-white text-position-left page4-info-item">Vision:....................</h3> -->
                <!-- </div>
                <div class="page4-image-container">
                  <img class="page4-image" src="../assets/BrainscanPage4.png" alt="brainscan image" />
                </div>
              </div> -->

              <!-- ContactUs -->
              <div ref="ContactUs" class="mt-4">

                <div style="background-color: rgb(47, 57, 90);"
                  class="border-b-2 border-white footerHeadColor font-normal">
                  <v-row class="grid grid-cols-1 lg:grid-cols-2">

                    <!-- <v-col class="mb-0.5 lg:mb-3" @click="gotoGoogle()"> -->
                    <v-col class="mt-0.5 mb-0.5 lg:mb-3">
                      <div class="">
                        <img class=" grow" src="@/assets/doc-pen.jpg" alt="Think Tank" />
                      </div>
                      <!-- <p class="text-2xl hover:text-blue-200 text-center">
                                    <a href="https://goo.gl/maps/KTjmHJANqq9V3H996" class="hover:no-underline hover:text-blue-300">
                                    <span class="text-gray-100">
                                            Упътване в Google Maps
                                            <i class="text-gray-100 text-xl pt-6 fa fa-arrow-right"></i>
                                    </span>

                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.2091481337293!2d23.2972904159405!3d42.67811182294824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa851fdabf18cb%3A0x18e3a6533f93e940!2z0LHRg9C7LiDigJ7QkdGK0LvQs9Cw0YDQuNGP4oCcIDEzLCAxNDA4INC2LtC6LiDQodGC0YDQtdC70LHQuNGJ0LUsINCh0L7RhNC40Y8!5e0!3m2!1sbg!2sbg!4v1644223649703!5m2!1sbg!2sbg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                                    </a>
                                </p> -->
                    </v-col>

                    <v-col class="my-auto md:text-3xl text-center">
                      <div class="my-auto md:p-8 border-b-2 text-white">
                        <span class="">Contact Us</span>
                      </div>
                      <div class="inline-block text-left mt-8">
                        <div class="inline-block text-gray-50 text-left -ml-6 md:ml-0">
                          <div class="flex text-sm md:text-3xl">
                            <i class="text-sm md:text-3xl ml-6 mr-6 mt-6 fa fa-address-card-o"></i>
                            Bulgaria, Pomorie, 
                            <br>Opalchenska 2B str.,
                            <br>+359 887 661 344
                          </div>
                        </div>

                        <div class="lg:hidden border-b-2"></div>

                        <div class="mt-3 mb-3">
                          <a href="mailto:tt@thinktank-bg.com"
                            class="text-gray-100 hover:no-underline hover:text-gray-700">
                            <div
                              class="flex text-gray-100 hover:no-underline hover:text-gray-400  text-sm md:text-3xl -ml-6 md:ml-0 ">
                              <i class=" text-sm md:text-3xl ml-6 mr-6 fa fa-envelope-open-o"></i>
                              tt@thinktank-bg.com
                            </div>
                          </a>
                        </div>

                      </div>

                    </v-col>

                    <!-- <v-col class="mb-0.5 lg:mb-3" @click="gotoGoogle()">
                                <iframe class="w-full lg:w-11/12 h-56 lg:h-64" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.2091481337293!2d23.2972904159405!3d42.67811182294824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa851fdabf18cb%3A0x18e3a6533f93e940!2z0LHRg9C7LiDigJ7QkdGK0LvQs9Cw0YDQuNGP4oCcIDEzLCAxNDA4INC2LtC6LiDQodGC0YDQtdC70LHQuNGJ0LUsINCh0L7RhNC40Y8!5e0!3m2!1sbg!2sbg!4v1644223649703!5m2!1sbg!2sbg"
                                            allowfullscreen="" loading="lazy"></iframe>
                                <p class="text-2xl hover:text-blue-200 text-center">
                                    <a href="https://goo.gl/maps/KTjmHJANqq9V3H996" class="hover:no-underline hover:text-blue-300">
                                    <span class="text-gray-100">
                                            Упътване в Google Maps
                                            <i class="text-gray-100 text-xl pt-6 fa fa-arrow-right"></i>
                                    </span>

                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.2091481337293!2d23.2972904159405!3d42.67811182294824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa851fdabf18cb%3A0x18e3a6533f93e940!2z0LHRg9C7LiDigJ7QkdGK0LvQs9Cw0YDQuNGP4oCcIDEzLCAxNDA4INC2LtC6LiDQodGC0YDQtdC70LHQuNGJ0LUsINCh0L7RhNC40Y8!5e0!3m2!1sbg!2sbg!4v1644223649703!5m2!1sbg!2sbg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                                    </a>
                                </p>  
                        </v-col> -->

                  </v-row>
                </div>

                <!-- Долна секция -->
                <div class="footerBottom shadow-md py-1">
                  <div class=" shadow-sm" @click="$scrollToTop">
                    <p class="text-center text-sm md:text-3xl font-normal text-gray-50">
                      Think Tank &copy;
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </v-col>
      </v-window-item>

      <v-window-item :value="2">
        <div class="max-w-screen-2xl w-screen card">
          <v-btn class="my-4" color="#6495ED" elevation="8" size="x-large" block @click="step = 1">
            Back
          </v-btn>

          <div class="app-header">
            <template v-if="isLoading"> Loading... </template>

            <template v-else>
              <span v-if="showAllPages"> {{ pageCount }} page(s) </span>

              <span v-else>
                <button :disabled="page <= 1" @click="page--">❮</button>
                {{ page }} / {{ pageCount }}
                <button :disabled="page >= pageCount" @click="page++">❯</button>
              </span>

              <label class="right">
                <input v-model="showAllPages" type="checkbox" />
                Show all pages
              </label>
            </template>
          </div>

          <div class="app-content">
            <vue-pdf-embed ref="pdfRef" :source="pdfSource" :page="page" @password-requested="handlePasswordRequest"
              @rendered="handleDocumentRender" />
          </div>
        </div>
      </v-window-item>

    </v-window>
  </v-row>
  <!-- </v-card> -->
</template>

<script>
// import Vue from "vue";

export default {
  name: 'Home',
  data() {
    return {
      ref: null,
      step: 1,
      isLoading: true,
      page: null,
      pageCount: 1,
      showAllPages: true,

      images: [],
      pdfBaseAddress: 'https://thinktank-bg.com/img/pdf/',
      pdfSource: '',
      items: [
        {
          src: 'https://www.thinktank-bg.com/balt/1.png',
        },
        {
          src: 'https://www.thinktank-bg.com/balt/2.png',
        },
        {
          src: 'https://www.thinktank-bg.com/balt/3.png',
        },
        {
          src: 'https://www.thinktank-bg.com/balt/4.png',
        },
        {
          src: 'https://www.thinktank-bg.com/balt/5.png'
        }
      ],
      drawer: null,
      links: [
        { icon: 'mdi-format-list-bulleted-square', text: 'Our products', route: '/' },
        { icon: 'mdi-format-list-bulleted-square', text: 'Mission', route: '/' },
        { icon: 'mdi-gesture-tap-button', text: 'Contact', route: '/' }
      ]
    }
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1
    }
  },
  methods: {
    loadPdf(selectedDevice) {
      this.pdfSource = "";
      this.pdfSource = this.pdfBaseAddress + selectedDevice + ".pdf";
      this.step = 2;
    },
    handleDocumentRender(args) {
      console.log(args)
      this.isLoading = false
      this.pageCount = this.$refs.pdfRef.pageCount
    },

    handlePasswordRequest(callback, retry) {
      callback(prompt(retry ? 'Enter password again' : 'Enter password'))
    }
  }
  //     // handleClick() {
  //     //     console.log('TEST');
  //     //   this.$refs.ContactUs.scrollIntoView({ behavior: 'smooth' });
  //   }
}
</script>
<style lang="scss" scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.footerHeadColor {
  //min-width: 360px;
  //z-index:900;  /* high z index so other content scrolls underneath */
  //background-color: #51579c8f;
  background-color: #eab308;
  // color: #51579c59;
}

.footerBottom {
  //min-width: 360px;
  //background-color: rgb(198, 200, 217);
  background-color: rgb(47, 57, 90);
}


/*
  FROM BASE
*/
body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* These styles must to copied on every separated ccs file (if this landing page is needed to be sliced on pieces) */
.text-white {
  color: whitesmoke;
}

.text-position-center {
  margin: 0 10%;
  text-align: center;
}

.text-position-left {
  margin: 0 10%;
  text-align: left;
}

/* *********** Page1 Styling  *********** */
// .page1-container {
//     display: flex;
// }

.page1-img {
  min-width: 100%;
}

.page1-over-element {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  min-width: 45%;
  align-items: center;
  min-height: 100%;
  z-index: 2;
  right: 45%;
  position: relative;
  background: rgba(231, 233, 237, 0.7);
}

.page1-logo {
  max-width: 55%;
}



.learn-more-btn {


  padding: 5% 10%;
  background: rgba(237, 231, 231, 0.7);
}

/* *********** Page 2 Styling *********** */



div.page2-info-container h1::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: whitesmoke;
  margin-top: 3%;
}

.page2-icons-container {
  display: flex;
  justify-content: center;
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5%;
}

div.icon-container h4 {
  text-decoration: underline;
}

/* *********** Page 4 Styling *********** */


.page4-info-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(47, 57, 90);
  width: 60%;
  justify-content: center;
}

div.page4-info-container h1::after {
  content: "";
  display: block;
  height: 1px;
  background-color: whitesmoke;
  width: 100%;
  margin-top: 3%;
}

.page4-info-item {
  margin: 4% 10%;
}

.page4-image-container {
  width: 40%;
  /* height: 100%; */
}

.page4-image {
  min-height: 100%;
  max-width: 100%;
}
</style>