import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/Home.vue'
import ballast from '../views/Ballast.vue'
import charity from '../views/Charity.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/ballast',
    name: 'ballast',
    component: ballast
  },
  {
    path: '/charity',
    name: 'charity',
    component: charity
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
