
<template>
  <v-app>
      <!-- <div class=" sticky--ex container">
          <HeaderMobile class="md:hidden"/>
           >>>768px width -->
          <!-- <Header  class="hidden md:flex" />  -->
      <!-- </v-navigation-drawer> --> 
      <!-- </div> -->
      <!-- <v-main> -->
        <!-- Provides the application the proper gutter -->
        <!-- <v-container> -->
          <!-- If using vue-router -->
          <div class="max-w-screen-2xl mx-auto">
            <router-view></router-view>
          </div>
         
        <!-- <div class="container"> -->
          <!-- <transition name="component-fade" mode="out-in">
            <Footer/>
          </transition> -->
        <!-- </div> -->
        <!-- </v-container> -->
      <!-- </v-main> -->
    <!-- </div> -->

    <!-- <v-footer padless>
    </v-footer> -->

  </v-app>
</template>

<script>
//import Header from '@/components/Header.vue';
//import HeaderMobile from '@/components/HeaderMobile.vue';
//import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
  //  Header,
//    HeaderMobile,
    //Footer
  },
  data: () => ({
    //
  }),
};
</script>
<style scoped lang="scss">
@font-face {
  font-family: "Prometo";
  // src: url("./assets/MTCORSVA.eot");
  // src: local("MTCORSVA"), url("./assets/MTCORSVA.woff") format("woff"),
  //   url("./assets/MTCORSVA.ttf") format("truetype");
  src: local("Prometo"), url("./assets/Prometo.ttf") format("truetype");
}

.sticky--ex{
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 9999; 
}
 

#app {
  //scroll-behavior: ver;
  font-family: Prometo, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(90deg, rgba(25, 0, 92, 0.1),    rgba(39, 1, 1, 0.1),    rgba(124, 137, 153, 0.568)), url('./assets/bckgnd.svg');
  background-repeat: no-repeat;
  background-size: cover, cover;
}
</style>
